// LIBs
import styled from "styled-components";

// INCLUDEs
import { COLORS, FUNC, SIZES } from "../../core/StyleSheet/includes";

export const Container = styled.label`
  &.InputLabel,
  &.TextAreaLabel,
  &.InputMaskLabel {
    width: 100%;

    &.danger-input {
      span {
        color: #F00;
      }

      input,
      textarea {
        border-color: #F00;
      }
    }

    span {
      margin-bottom: 10px;
      ${FUNC.font('4vw', 200)}
      transition: all ease 0.5s;

      display: block;

      @media ${SIZES.mbB} {
        font-size: 18px;
        line-height: 20px;
      }
    }

    input,
    textarea {
      width: 100%;
      padding: 10px 0;
      border-bottom: 1px solid #000;
      ${FUNC.font('4vw', 200)}
      background-color: transparent;
      transition: all ease 0.5s;

      &:hover {
        border-bottom-width: 2px;
      }

      &:focus {
        border-bottom-width: 2px;
      }

      @media ${SIZES.mbB} {
        font-size: 18px;
        line-height: 20px;
      }
    }

    textarea {
      height: 200px;
    }
  }
`;
