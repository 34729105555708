// LIBs
import styled from "styled-components";

// STYLESHEETs
import { COLORS, SCREENS } from "../../../core/StyleSheets/includes";

export const Container = styled.section`
  .ProductContainer {
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;
    padding-top:120px;

    &__title {
      text-align: center;
      margin-bottom: 40px;

      h3 {
        font-size: 7vw;
        font-weight: 700;
        line-height: 1.1;
        text-transform: uppercase;

        @media ${SCREENS.tb_min} {
          font-size: 2.5vw;
        }
      }
    }

    &__box {
      .ProductBox {
        padding: 16px;
        box-shadow: 2px 2px 12px 0px rgba(0, 0, 0, 0.25);
        background-color: ${COLORS.white};
        border-radius: 10px;

        display: flex;
        flex-direction: column;

        @media ${SCREENS.dsS_min} {
          padding: 32px;

          flex-direction: row;
          align-items: center;
        }

        &__list {
          order: 1;

          display: flex;
          flex-direction: column;

          @media ${SCREENS.dsS_min} {
            padding-right: 24px;
            border-right: 1px solid #D8DEF1;

            order: 0;
            flex: 1;
          }

          div:nth-of-type(4) {
            border-bottom: 0;
          }

          >div {
            border-bottom: 1px solid #D8DEF1;

            &:last-child {
              border-bottom: unset;
            }
          }

          button {
            margin-top: 24px;
            border-radius: 5px;
            text-transform: uppercase;
            padding: 16px 30px;

            @media ${SCREENS.dsS_min} {
              display: none;
            }
          }
        }

        &__content {
          margin-bottom: 24px;

          @media ${SCREENS.dsS_min} {
            flex: 1;
            padding-left: 24px;
          }

          &--title {
            margin-bottom: 16px;

            p {
              font-size: 7vw;
              font-weight: 600;
              line-height: 8vw;

              @media ${SCREENS.tb_min} {
                font-size: 2.5vw;
                line-height: 3.5vw;
              }

              @media ${SCREENS.dsS_min} {
                font-size: 32px;
                line-height: 42px;
              }
            }
          }

          &--body {
            display: flex;
            flex-direction: column;
            gap: 20px;

            p {
              font-size: 4vw;
              line-height: 4.5vw;

              @media ${SCREENS.tb_min} {
                font-size: 1.5vw;
                line-height: 2.5vw;
              }

              @media ${SCREENS.dsS_min} {
                font-size: 16px;
                line-height: 26px;
              }
            }
          }

          &--button {
            display: none;

            &--box{
              margin-top: 37px;
              border-radius: 5px;
              text-transform: uppercase;
              padding: 16px 30px;

              &--mobile{
                margin-top: 37px;
                border-radius: 5px;
                text-transform: uppercase;
                padding: 16px 30px;
                display: block;
              }
            }

            @media ${SCREENS.dsS_min} {
              display: block;
            

            &--box {
              &--mobile{
                display: none;
              }
            }
            }
          }
        }
      }
    }
  }
`;
