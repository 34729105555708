// LIBs
import ReactGA from "react-ga4";
import { useNavigate } from "react-router-dom";

// COMPONENTs
import Button from "../Button";

// STYLEs
import * as C from "./styles";

export default props => {
  const navigate = useNavigate();

  function goPage() {
    ReactGA.event({
      action: `Clicou ${props.title}`,
      category: "click"
    });
    
    navigate("/faq");
  }

  return (
    <C.Container 
      aria-hidden={props.ariaHidden}
    >
      <div className="BannerItemBG">
        <picture>
          <source srcSet={props.srcLite} type="image/webp"/>
          <source srcSet={props.src} type="image/png" />
          <img src={props.src} alt="" />
        </picture>
      </div>
      <div className="BannerItemContainer">
        <div className="BannerItemContainer__title">
          <p>{props.title}</p>
        </div>

        <div className="BannerItemContainer__text">
          <div 
            className="BannerItemContainer__text--text" 
            dangerouslySetInnerHTML={{
              __html: props.text
            }}
          />

          <div className="BannerItemContainer__text--button">
            <Button 
              label="Saiba Mais"
              onClick={goPage}
              button={{
                "aria-label": "Encontre respostas para suas dúvidas na seção de Perguntas Frequentes (FAQ)"
              }}
            />
          </div>
        </div>
      </div>
    </C.Container>
  )
}