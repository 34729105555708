// LIBs
import styled from "styled-components";

// INCLUDEs
import { COLORS, FUNC, SIZES } from "../../core/StyleSheet/includes";

export const Container = styled.div`
  @media ${SIZES.tb} {
    max-width: 225px;
  }

  .CardFunctionalitiesIcon {
    margin-bottom: 10px;

    @media ${SIZES.dsS} {
      margin-bottom: 20px;
    }

    span {
      font-size: 6vw;
      color: #FFF;

      @media ${SIZES.mbB} {
        font-size: 24px;
      }
    }
  }

  .CardFunctionalitiesTitle {
    margin-bottom: 10px;

    @media ${SIZES.dsS} {
      margin-bottom: 30px;
    }

    p {
      max-width: 165px;
      width: 39vw;
      ${FUNC.font('4.3vw', 200, '#FFF')}

      @media ${SIZES.mbB} {
        width: 100%;
        font-size: 20px;
        line-height: 22px;
      }

      @media ${SIZES.dsS} {
        max-width: 205px;
        font-size: 24px;
        line-height: 26px;  
      }
    }
  }

  .CardFunctionalitiesText {
    p {
      ${FUNC.font('3.8vw', 200, '#FFF', '5.8vw')}

      @media ${SIZES.mbB} {
        font-size: 16px;
        line-height: 26px;
      }

      @media ${SIZES.dsS} {
        font-size: 12px;
        line-height: 22px;
      }
    }
  }
`;
