// LIBs
import styled from "styled-components";

// INCLUDES
import { COLORS, SCREENS } from "../../core/StyleSheets/includes";

export const Container = styled.header`
    min-width: fit-content;
    border-top: 1px solid #FFF;
    margin-top: 8px;

    @media ${SCREENS.tb_min} {
        border-top: none;
        margin-top: 0;
        position: absolute !important;
        left: 0;
        right: 0;
        top: 100%;
        background-color: ${COLORS.white};
        box-shadow: 2px 2px 12px 0px rgba(0, 0, 0, 0.25);

        ${props => props.alignRight ? `
            top: 0;
            left: 100%;
            right: unset;
        ` : ''}
    }

    
    .headerDropdown--item{
        padding: 8px;
        background-color: #fff;
         a {
            color: #000 !important;
         }

        &:not(:last-child){
            border-bottom: 1px solid ${COLORS.gray_l};
        }

        &:last-child{
            padding: 8px 8px 10px 8px;
            margin-bottom: -10px;

            @media ${SCREENS.tb_min} {
                padding:8px;
            }
        }
    }

    a {
        svg {
            transform: rotate(90deg);

            &.active {
                transform: rotate(-90deg);

                @media ${SCREENS.tb_min} {
                    transform: rotate(0);
                }
            }

            @media ${SCREENS.tb_min} {
                transform: rotate(0);
            }
        }

        @media ${SCREENS.tb_min} {
            width: 100% !important;
            text-align: left !important;
            display: flex;
            justify-content: space-between !important;
            align-items: center;
            color: black !important;
        }
    }
`;
