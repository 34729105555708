// LIBs
import styled from "styled-components";

// INCLUDEs
import { COLORS, FUNC, SCREENS } from "../../core/StyleSheets/includes";

export const Container = styled.div`
  position: absolute;
  z-index: 1;
  top: 50%;
  right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  width: min-content;
  border-radius: 100px;
  background: #fff;
  box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.25);
  
  @media ${SCREENS.dsS_min} {
    display: none;
  }

  svg {
    width: 24px;
    height: 24px;
    color: ${COLORS.first};
  }
}


  .left {
    right: unset;
    left: 8px;

    @media ${SCREENS.dsS_min} {
      display: none;
    }
  }
`;
