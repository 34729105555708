// LIBs
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

// CONTAINERs
import Details from "../../containers/Hilight/Details";

// SERVICEs
import { getBlock } from "../../core/services/Api";

// UTILs
import { convertDataPageApiInObject } from "../../core/utils/CDPAIO-1.0.0";

// STYLEs
import * as C from "./styles";

const names = {
  landing_page: 0,
  one_page: 1,
  loja_virtual: 2,
  suporte_tecnico: 3,
}

function Hilights(props) {
  const { index } = useParams();

  const [ hilight, setHilight ] = useState(null);

  useEffect(() => {
    start();
  }, [])

  async function start() {
    props.onShownLoad();
    
    const req = await getBlock(7, "default");
    const reqDecoded = jwtDecode(req.data.token);
    const newHilight = convertDataPageApiInObject([reqDecoded.data], "children").destaques[names[index]];
    setHilight(newHilight);
    document.title = `Mdsystem Web - ${newHilight.title}`;

    window.scrollTo(0, 0);
    props.onHideLoad();
  }
  
  return (
    <C.Container className="HilightsView">
      { hilight &&
        <Details data={hilight} />
      }
    </C.Container>
  )
}

export default Hilights;