// LIBs
import styled from "styled-components";

// INCLUDEs
import { COLORS, FUNC, SCREENS } from "../../../core/StyleSheets/includes";

export const Container = styled.section`
  .BannerContainer {
    width: 100%;

    .slick-dots {
      bottom: 100px;
      z-index: 2;
      
      @media ${SCREENS.dsB_min} {
        bottom: 185px;
      }

      li {
        &.slick-active {
          button {
            &::before {
              color: ${COLORS.second};
            }
          }
        }

        button {
          &:hover {
            &::before {
              color: ${COLORS.second};
            }
          }

          &::before {
            font-size: 14px;
            color: ${COLORS.white};
            opacity: 1;
            transition: all ease .5s;
          }
        }
      }
    }
  }

  .BannerBorder {
    width: 100%;
    height: 120px;
    margin-top: -119px;

    position: relative;
    z-index: 1;

    img {
      width: 100%;
      height: 100%;

      object-fit: cover;
      object-position: right;

      @media ${SCREENS.tb_min} {
        object-fit: unset;
      }
    }
  }
`;