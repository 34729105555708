// LIBs
import styled from "styled-components";

// INCLUDEs
import { COLORS, FUNC, SCREENS } from "../../core/StyleSheets/includes";

export const Container = styled.div`
  padding: 16px;
  border: 1px solid #C4C4C4;
  border-radius: 8px;

  &.active-faq-card {
    .FAQCardTitle {
      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: 1px solid #C4C4C4;

      svg {
        transform: rotate(180deg);
      }
    }
    
    .FAQCardText {
      display: flex;
    }
  }

  .FAQCardTitle {
    padding-right: 10px;
    cursor: pointer;

    display: flex;
    gap: 20px;
    justify-content: space-between;
    align-items: center;

    p {
      ${FUNC.font(14, 700)}
      line-height: 1.4;
    }

    svg {
      font-size: 30px;
      transition: all ease .5s;
    }
  }

  .FAQCardText {
    display: none;
    flex-direction: column;
    gap: 20px;

    p {
      ${FUNC.font(14, 400, '#333')}
      line-height: 1.4;
    }
  }
`;
