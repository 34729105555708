// LIBs
import styled from "styled-components";

// INCLUDEs
import { COLORS, FUNC, SCREENS } from "../../../core/StyleSheets/includes";

export const Container = styled.section`
  padding: 20px 0;
  margin-bottom: 80px;

  position: relative;

  @media ${SCREENS.tb_min} {
    padding: 0;
    margin-bottom: 160px;
  }

  .HilightsBg {
    width: 100%;
    height: auto;

    position: absolute;
    top: 12%;
    z-index: -1;

    @media ${SCREENS.tb_min} {
      top: -12%;
    }

    @media ${SCREENS.dsS_min} {
      height: 100%;
      top: 10%;
    }
  }

  .HilightsContainer {
    max-width: 1320px;
    width: 100%;
    margin: 0 auto;
    padding: 0 16px;

    &__title {
      text-align: center;
      margin-bottom: 45px;

      h2 {
        font-size: 7vw;
        font-weight: 700;
        line-height: 1.1;

        @media ${SCREENS.tb_min} {
          font-size: 2.5vw;
        }
      }
    }

    &__list {
      /* display: flex;
      flex-wrap: wrap;
      gap: 20px;
      justify-content: center; */

      .slick-dots {
        bottom: -50px;

        li {
          button {
            &::before {
              font-size: 10px;
              color: ${COLORS.first};
            }
          }
        }
      }

      .slick-slide {
        padding: 10px;

        @media ${SCREENS.dsS_min} {
          padding: 10px;
        }
      }

      .HilightItem {
        /* width: calc(50% - 10px); */

        @media ${SCREENS.dsS_min} {
          /* width: calc(25% - 20px); */
        }

        .HilightItemContainer {
          &__image {
            @media ${SCREENS.dsS_min} {
              /* height: 380px; */

              img {
                /* height: 380px; */
              }
            }
          }
        }
      }
    }
  }

  .Slider-Dots {
    position: absolute;
    bottom: -25px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;

    & li {
      position: relative;
      display: inline-block;
      width: 20px;
      height: 20px;
      margin: 0 5px;
      padding: 0;
      cursor: pointer;

      &.slick-active button:before {
        opacity: 1;
        color: ${COLORS.second};
      }

      & button {
        font-size: 0;
        line-height: 0;
        display: block;
        width: 20px;
        height: 20px;
        padding: 5px;
        cursor: pointer;
        color: transparent;
        border: 0;
        outline: none;
        background: transparent;

        &:before {
          font-family: "slick";
          font-size: 16px;
          line-height: 20px;
          position: absolute;
          top: 0;
          left: 0;
          width: 20px;
          height: 20px;
          content: "•";
          text-align: center;
          opacity: 1;
          color: ${COLORS.sixth};
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }
      }
    }
  }
`;
