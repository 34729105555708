// ICONs
import { MdOutlineClose } from "react-icons/md";

// STYLEs
import * as C from "./styles";

export default (props) => {
  const closeModal = (event) => {
    if (event.target.classList.contains("active-policy-cookie")) props.onHide();
  }

  return (
    <C.Container onClick={closeModal} className={props.active ? "active-policy-cookie" : ""}>
      <div className="PolicyCookieCotainer">
        <div className="PolicyCookieCotainer__header">
          <p>Política de Cookies</p>
          <button onClick={props.onHide}>
            <MdOutlineClose />
          </button>
        </div>
        <div className="PolicyCookieCotainer__content">
          <div className="PolicyCookieCotainer__content--container">
            <strong>Política de Cookies do Site Mdsystem Web</strong>
            <p><strong>Última atualização:</strong> 19/10/2023</p>
            <strong>1. Introdução</strong>
            <p>Esta Política de Cookies explica como o site <a href="https://mdsystemweb.com.br">https://mdsystemweb.com.br</a> (a seguir denominado "nosso site" ou "site") usa cookies e tecnologias semelhantes para coletar e processar informações sobre você. Esta política também fornece informações sobre como você pode controlar o uso de cookies.</p>
            <strong>2. O que são cookies?</strong>
            <p>Cookies são pequenos arquivos de texto que são armazenados no seu dispositivo quando você visita um site. Eles contêm informações que podem ser lidas por servidores da web quando você retorna ao site posteriormente. Os cookies podem ser temporários (conhecidos como "cookies de sessão") ou persistentes, dependendo do tempo que permanecem em seu dispositivo.</p>
            <strong>3. Como usamos cookies</strong>
            <p>Nós usamos cookies no nosso site para diversos fins, incluindo:</p>
            <ul>
              <li><p><strong>Melhoria da experiência do usuário:</strong> Utilizamos cookies para melhorar a funcionalidade do site e a experiência do usuário.</p></li>
              <li><p><strong>Análise de dados:</strong> Cookies nos ajudam a entender como os visitantes interagem com o site, fornecendo informações sobre as áreas visitadas com mais frequência e eventuais problemas encontrados.</p></li>
              <li><p><strong>Publicidade direcionada:</strong> Podemos usar cookies para mostrar anúncios que acreditamos ser relevantes para você com base em suas atividades no site.</p></li>
            </ul>
            <strong>4. Tipos de cookies que usamos</strong>
            <p>A seguir estão os principais tipos de cookies que utilizamos:</p>
            <ul>
              <li><p><strong>Cookies estritamente necessários:</strong> Esses cookies são essenciais para o funcionamento do site e não podem ser desativados em nossos sistemas.</p></li>
              <li><p><strong>Cookies de desempenho:</strong> Esses cookies nos ajudam a entender como os visitantes interagem com o site, fornecendo informações sobre as áreas visitadas com mais frequência e eventuais problemas encontrados.</p></li>
              <li><p><strong>Cookies de funcionalidade:</strong>  Esses cookies permitem ao site lembrar as escolhas que você faz, como idioma ou região, para fornecer uma experiência mais personalizada.</p></li>
              <li><p><strong>Cookies de publicidade:</strong> Esses cookies são usados para mostrar anúncios que acreditamos ser relevantes para você com base em suas atividades de navegação.</p></li>
            </ul>
            <strong>5. Dados pessoais coletados</strong>
            <p>Além de informações obtidas por meio de cookies, coletamos os seguintes dados pessoais:</p>
            <ul>
              <li><p>Nome</p></li>
              <li><p>Endereço de e-mail</p></li>
              <li><p>Número de telefone</p></li>
              <li><p>CPF (para pessoas físicas)</p></li>
              <li><p>CNPJ (para pessoas jurídicas)</p></li>
              <li><p>Endereço</p></li>
            </ul>
            <strong>6. Uso de cookies no cadastro no CMS</strong>
            <p>Em adição aos dados pessoais mencionados acima, utilizamos cookies no processo de cadastro na nossa plataforma de Content Management System (CMS). Esses cookies são essenciais para permitir o acesso e uso da plataforma e para autenticar os usuários durante o processo de registro e login.</p>
            <strong>7. Como controlar cookies</strong>
            <p>Você pode gerenciar suas preferências de cookies, bloquear ou excluir cookies a qualquer momento através das configurações do seu navegador. No entanto, observe que a desativação de cookies pode afetar a funcionalidade do site e a capacidade de usar nossa plataforma de CMS.</p>
            <strong>8. Alterações na política de cookies</strong>
            <p>Esta política de cookies pode ser atualizada periodicamente para refletir alterações em nossas práticas de uso de cookies. A versão mais recente da política será sempre publicada no site.</p>
            <strong>9. Entre em contato conosco</strong>
            <p>Se você tiver alguma dúvida ou preocupação sobre esta política de cookies, a coleta de dados pessoais ou o uso de cookies no processo de cadastro na plataforma de CMS, entre em contato conosco através do seguinte endereço de e-mail: <a href="mailto:contato@mdsystemweb.com.br">contato@mdsystemweb.com.br</a></p>
          </div>
        </div>
      </div>
    </C.Container>
  );
}
