// LIBs
import axios from "axios";

const token = "77c5cc04767544812df4f38182051c51";

const dashApi = axios.create({
  baseURL: "https://dashboard-customer.mdsystemweb.com.br/api/v2",
  headers: {
    "Authorization": `Bearer ${token}`,
  },
});

export const getBlocksAll = async () => {
  try {
    const req = await dashApi.get("/blocks/md-promotional-dash");

    return req;
  } catch (err) {
    console.error(err);
    return null;
  }
}

export const getMenus = async () => {
  try {
    const req = await dashApi.get("/menus/md-promotional-dash");

    return req;
  } catch (err) {
    console.error(err);
    return null;
  }
}

export const sendDash = async (params) => {
  try {
    const req = await dashApi.post("/contact", params);

    return req;
  } catch (err) {
    console.error(err);
    return null;
  }
}