// LIBs
import ReactGA from "react-ga4";
import { Link } from "react-router-dom";
import { FiChevronDown, FiChevronUp  } from "react-icons/fi";

// IMAGEs
import logo from "../../assets/logo.png";
import logoLite from "../../assets/logo.webp";

// STYLEs
import * as C from "./styles";
import HeaderDropdown from "../HeaderDropdown";
import { useState } from "react";

export default props => {
  const [pageApiOpen, setPageApiOpen] = useState(false)

  let headerClass = "Header";
  let buttonClass = "HeaderContainer__button";
  let menuClass = "HeaderContainer__menu";

  if (props.colored) headerClass += " color-menu";
  if (props.show) {
    buttonClass += " active-button";
    menuClass += " active-menu";
    headerClass += " none-color";
  }

  function scrollTo(event, to, title) {
    const element = document.querySelector(to.indexOf("#") > -1 ? to : `#${to}`);
    
    if (element) {
      event.preventDefault();
      const top = element.offsetTop;
      window.scrollTo({
        top,
        behavior: "smooth"
      });
    } else {
      props.onScrollTo(to);
    }

    ReactGA.event({
      action: `Menu ${title}`,
      category: "click",
    });

    props.onToggleMenu(false);
  }

  return (
    <C.Container className={headerClass}>
      <div className="HeaderContainer">
        <div className="HeaderContainer__logo">
          <Link to="/" onClick={() => props.onScrollTo("#home")}>
            <picture>
              <source srcSet={logoLite} type="image/webp" />
              <source srcSet={logo} type="image/png" />
              <img 
                src={logo} 
                width={127}
                height={28}
                alt="Logo MDSystem Web" 
              />
            </picture>
          </Link>
        </div>

        <div 
          // aria-label="Toque aqui para acessar o menu" 
          aria-hidden="true"
          onClick={() => props.onToggleMenu(!props.show)} 
          className={buttonClass}
        >
          <div className="ButtonMob">
            <div className="ButtonMob__bar" />
            <div className="ButtonMob__bar" />
            <div className="ButtonMob__bar" />
          </div>
        </div>

        <div className={menuClass}>
          <nav className="Nav">
            <ul className="Nav__container">
              { (props.data && props.data.length > 0) &&
                props.data.map((element, index) => {
                  const menusDisabled = [17, 23, 32];
                  if (!menusDisabled.includes(element.id)) {
                    return (
                      <li 
                        key={element.id} 
                        onMouseLeave={()=>setPageApiOpen(false)}
                      >
                        {element.child ? 
                        <>
                          <a
                            onClick={()=>setPageApiOpen(!pageApiOpen)}
                            onMouseEnter={()=>setPageApiOpen(true) }
                            aria-label="Acesse rapidamente a seção correspondente do site"
                          >
                            {element.title}
                            {!pageApiOpen 
                            ? <FiChevronDown size={16} style={{verticalAlign:"middle"}}/> 
                            : <FiChevronUp size={16} style={{verticalAlign:"middle"}}/>}
                          </a>
                          <HeaderDropdown 
                            onClick={()=>setPageApiOpen(false)} 
                            open={pageApiOpen} 
                            items={[
                              ...element.child
                            ]}
                            onCloseMenu={props.onToggleMenu}
                          />
                        </>:
                        <Link 
                          to="/"
                          onClick={event => scrollTo(event, element.link, element.title)}
                          aria-label="Acesse rapidamente a seção correspondente do site"
                        >
                          {element.title}
                        </Link>}
                      </li>
                    )
                  } else {
                    return false;
                  }
                })
              }
            </ul>
          </nav>
        </div>
      </div>
    </C.Container>
  )
}
