// LIBs
import InputMask from "react-input-mask";

// STYLEs
import * as C from "./styles";

export default props => {
  switch(props.type) {
    case "mask":
      let maskClass = "InputMaskLabel";

      if (props.danger) maskClass += " danger-input";

      return (
        <C.Container className={maskClass}>
          <span>{props.label}</span>
          <InputMask 
            value={props.value} 
            mask={props.mask}
            onChange={props.onChange}
            placeholder={props.placeholder} 
            readOnly={props.readOnly}
            {...props.input}
          />
        </C.Container>
      )
    case "textarea":
      let textAreaClass = "TextAreaLabel";

      if (props.danger) textAreaClass += " danger-input";

      return (
        <C.Container className={textAreaClass}>
          <span>{props.label}</span>
          <textarea 
            value={props.value} 
            onChange={props.onChange}
            placeholder={props.placeholder}
            readOnly={props.readOnly}
            {...props.textarea}
          />
        </C.Container>
      )
    default:
      let inputClass = "InputLabel";

      if (props.danger) inputClass += " danger-input";

      return (
        <C.Container className={inputClass}>
          <span>{props.label}</span>
          <input 
            type={props.type} 
            value={props.value} 
            onChange={props.onChange}
            placeholder={props.placeholder} 
            readOnly={props.readOnly}
            {...props.input}
          />
        </C.Container>
      );
  }
}