// LIBs
import { Link } from "react-router-dom";

// STYLEs
import * as C from "./styles";

export default props => {
  switch (props.type) {
    case "link-external":
      let linkExternalClass = "LinkExternal";

      if (props.className) linkExternalClass += ` ${props.className}`;

      return (
        <C.Container secondary={props.secondary}>
          <a 
            href={props.to} 
            className={linkExternalClass}
            target="_blank"
            onClick={props.onClick} rel="noreferrer"
          >
            {props.label}
          </a>
        </C.Container>
      )
    case "link":
      return (
        <C.Container secondary={props.secondary}>
          <Link
            to={props.to}
            className={`Link ${props.className}`}
          >
            {props.label}
          </Link>
        </C.Container>
      )
    default:
      let buttonClass = "Button";

      if (props.className) buttonClass += ` ${props.className}`;

      return (
        <C.Container secondary={props.secondary}>
          <button className={buttonClass} onClick={props.onClick} {...props.button}>
            {props.label}
          </button>
        </C.Container>
      )
  }
}