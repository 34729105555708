// LIBs
import styled from "styled-components";

// INCLUDEs
import { COLORS, FUNC, SCREENS } from "../../core/StyleSheets/includes";

export const Container = styled.label`
  &.LabelInput,
  &.LabelMask,
  &.LabelTextArea {
    width: 100%;
    height: 56px;
    border-radius: 999px;
    border: 2px solid #C2C2C2;
    padding: 0 20px;
    cursor: text;

    position: relative;
    display: flex;
    align-items: center;

    span {
      padding: 3px 8px;
      background-color: ${COLORS.white};
      margin-top: -60px;
      ${FUNC.font(16, 400, '#C2C2C2', 16)};

      display: block;

      position: absolute;
    }
  }

  &.LabelTextArea {
    padding: 20px;
    height: 223px;
    border-radius: 20px;

    align-items: flex-start;

    span {
      margin-top: -35px;
    }

    textarea {
      width: 100%;
      height: 100%;
    }
  }

  &.LabelCheck {
    cursor: pointer;

    display: flex;
    align-items: center;

    input {
      display: none;
    }

    input:checked + div {
      background-color: ${COLORS.first};
    }

    div {
      width: 20px;
      height: 20px;
      border: 2px solid #C2C2C2;
      margin-right: 10px;
      transition: all ease 0.8s;
    }

    span {
      font-size: 16px;

      flex: 1;

      a {
        font-size: 16px;
        color: ${COLORS.second};

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
`;
