// LIBs
import styled from "styled-components";

// INCLUDEs
import { COLORS, FUNC, SIZES } from "../../core/StyleSheet/includes";

export const Container = styled.header`
  width: 100%;
  background-color: ${COLORS.first};
  padding: 20px 0;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);

  position: relative;
  z-index: 1;

  &.fixed-menu {
    border-top: 1px solid #FFF;

    position: fixed;
    top: 0;
    left: 0;
  }

  .HeaderPromotionalContainer {
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;

    display: flex;
    gap: 20px;
    justify-content: flex-end;
    align-items: center;

    &__btn {
      width: 50px;

      display: flex;
      justify-content: center;

      @media ${SIZES.tbV} {
        display: none;
      }

      &.active-btn-menu {
        .BtnMenu {
          z-index: 1;

          &__bar {
            &:nth-of-type(1),
            &:nth-of-type(3) {
              width: 100%;
              margin: 0;
            }

            &:nth-of-type(1) {
              transform: rotate(45deg);
            }

            &:nth-of-type(2) {
              opacity: 0;
            }

            &:nth-of-type(3) {
              transform: rotate(-45deg) translateX(0);
            }
          }
        }   
      }

      .BtnMenu {
        width: 30px;
        height: 30.5px;
        cursor: pointer;

        position: relative;

        &__bar {
          width: 100%;
          height: 4px;
          background-color: #FFF;
          transition: all ease 0.5s;

          position: absolute;
          left: 0;

          &:nth-of-type(1) {
            width: 60%;
            margin-top: 19px;
            margin-left: -2px;
            transform: rotate(-30deg) translateX(100%);
          }

          &:nth-of-type(2) {
            transform: rotate(90deg);
          }

          &:nth-of-type(3) {
            width: 60%;
            margin-top: 10px;
            margin-left: -1px;
            transform: rotate(30deg);
          }
        }
      }
    }

    &__nav {
      width: 100%;
      background-color: ${COLORS.first};
      pointer-events: none;
      opacity: 0;
      transform: translateY(-100%);
      transition: all ease 0.5s;

      display: flex;
      justify-content: center;
      align-items: center;

      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;

      &.active-menu {
        opacity: 1;
        transform: translateY(-1%);
        pointer-events: all;

        top: 100%;

        ul {
          margin-top: 0;
        }
      }

      @media ${SIZES.tbV} {
        opacity: 1;
        transform: translateY(0);
        pointer-events: all;
        
        justify-content: flex-end;

        position: relative;
        top: unset;
        left: unset;
        z-index: 1;
      }

      ul {
        width: 100%;
        text-align: center;
        margin-top: -25vh;
        transition: all ease 0.5s;

        @media ${SIZES.tbV} {
          margin-top: 0;
          justify-content: center;
          align-items: center;

          display: flex;
          gap: 20px
        }

        li {
          span {
            width: 100%;
            ${FUNC.font('5vw', 200, '#FFF')}
            padding: 30px;
            display: block;
            cursor: pointer;
            transition: all ease 0.5s;

            &.active-action {
              color: ${COLORS.second_light};
            }

            &:hover {
              color: ${COLORS.second};
            }

            @media ${SIZES.mbB} {
              font-size: 20px;
              line-height: 22px;
            }

            @media ${SIZES.tbV} {
              font-size: 16px;
              line-height: 20px;
              padding: 10px;
            }
          }
        }
      }
    }
  }
`;