//LIBs
import React from "react";

//STYLEs
import { Container } from "./styles";

//ASSETs
import MockupImg from "../../../assets/PageApiMockups.png";

//COMPONENTs
import Button from "../../../components/Button";

//SERVICEs
import { convertDataPageApi } from "../../../core/services/dataPageapi";

const PageApiBanner = (props) => {
  return (
    <Container>
      <div className="Page-Api-Banner__Content">
        <div className="Page-Api-Banner__Content-Container">
          <div className="Page-Api-Banner__CTA-Container">
            <div className="Page-Api-Banner__Texts">
              <p className="Page-Api-Banner__Title">{props.data.title}</p>
              <p
                className="Page-Api-Banner__Subtitle"
                dangerouslySetInnerHTML={{ __html: props.data.textarea }}
              ></p>
            </div>
            <Button
              className="Page-Api-Banner__Button"
              onClick={props.onClick}
              label="Visualizar Planos"
            />
          </div>
        </div>
        <div className="Page-Api-Banner__Mockup-Container">
          <img
            className="Page-Api-Banner__Mockup-Image"
            src={props.data.image}
            alt="Imagem Page Api Desktop e Mobile"
          />
        </div>
      </div>
    </Container>
  );
};
export default PageApiBanner;
