// LIBs
import { createRoot } from "react-dom/client";

// APPLICATION
import App from "./App";

// STYLESHEETs
import "./core/StyleSheets/index.css";

const root = createRoot(document.getElementById("root"));
root.render(
  <App />
);