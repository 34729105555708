//LIBs
import React from "react";

//STYLEs
import { Container } from "./styles";

const StepItem = (props) => {
  return (
    <Container>
      <div className="Page-Api-Step__Step">
        <div className="Page-Api-Step__Step--Count">
          <p>{props.step}</p>
        </div>
        <div className="Page-Api-Step__Step--Content">
          <p className="Page-Api-Step__Step--Title">{props.title}</p>
          <p
            className="Page-Api-Step__Step--Subtitle"
            dangerouslySetInnerHTML={{ __html: props.text }}
          ></p>
        </div>
      </div>
    </Container>
  );
};

export default StepItem;
