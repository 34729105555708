// COMPONENTs
import Button from "../Button";

// ASSETs
import politicaCookies from "../../assets/Politica_de_Cookies_do_Site_MD_System_Web.pdf";

// STYLEs
import * as C from "./styles";

export default (props) => {
  return (
    <C.Container className={props.active ? "popup-active" : ""}>
      <div className="PopupCookieContainer">
        <div className="PopupCookieContainer__title">
          <h4>Cookies & Privacidade</h4>
        </div>

        <div className="PopupCookieContainer__text">
          <p>Nós utilizamos cookies para melhorar a sua experiência de navegação. Ao continuar a utilizar o nosso site, você concorda com o uso de cookies de acordo com nossa <span onClick={props.onShow}>Leia mais</span>.</p>
        </div>

        <div className="PopupCookieContainer__buttons">
          <Button 
            label="Recusar Cookies"
            onClick={() => props.onResponse && props.onResponse(false)}
          />
          <Button 
            label="Aceitar Cookies"
            onClick={() => props.onResponse && props.onResponse(true)}
          />
        </div>
      </div>
    </C.Container>
  );
}
