// LIBs
import { useState } from "react";
import { toast } from "react-toastify";

// COMPONENTs
import Input from "../../components/Input";
import Button from "../../components/Button";

// ASSETs
import politicaPrivacidade from "../../assets/Politica_de_Privacidade_da_Mdsystemweb.pdf";

// STYLEs
import * as C from "./styles";

export default props => {
  const [ name, setName ] = useState("");
  const [ email, setEmail ] = useState("");
  const [ phone, setPhone ] = useState("");
  const [ subject, setSubject ] = useState("");
  const [ message, setMessage ] = useState("");

  function send() {
    if (name && email && phone && subject && message && props.onSend) {
      props.onSend(name, email, phone, subject, message);
      clear();
    }
  }

  function clear() {
    setName("");
    setEmail("");
    setPhone("");
    setSubject("");
    setMessage("");
  }

  return (
    <C.Container>
      <Input 
        type="text" 
        label="Nome" 
        value={name}
        onChange={event => setName(event.target.value)}
      />
      <Input 
        type="email" 
        label="Email" 
        value={email}
        onChange={event => setEmail(event.target.value)}
      />
      <Input 
        type="mask" 
        mask="(99) 9 9999-9999" 
        label="Telefone" 
        value={phone}
        onChange={event => setPhone(event.target.value)}
      />
      <Input 
        type="text" 
        label="Assunto" 
        value={subject}
        onChange={event => setSubject(event.target.value)}
      />
      <Input 
        type="textarea" 
        label="Mensagem" 
        value={message}
        onChange={event => setMessage(event.target.value)}
      />
      <Button 
        label="Enviar" 
        onClick={send} 
        button={{
          "aria-label": "Enviar formulário de contato"
        }}
      />
    </C.Container>
  )
}