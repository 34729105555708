// LIBs
import styled from "styled-components";

// INCLUDEs
import { COLORS, FUNC, SCREENS } from "../../core/StyleSheets/includes";

export const Container = styled.div`
  width: 100%;
  height: 530px;

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;

  &::before {
    content: '';
    width: 100%;
    height: 100%;
    background-color: ${COLORS.second_rgba};

    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }

  @media ${SCREENS.dsB_min} {
    height: 900px;
  }

  .BannerItemBG {
    width: 100%;
    height: 100%;

    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .BannerItemContainer {
    display: flex;
    flex-direction: column;
    align-items: center;

    position: relative;
    z-index: 1;

    @media ${SCREENS.tb_min} {
      padding: 0 20px;

      flex-direction: row;
      gap: 20px;
      align-items: flex-start;
    }

    @media ${SCREENS.dsB_min} {
      gap: 40px;
    }

    &__title {
      max-width: 290px;
      width: 100%;
      text-align: center;
      margin-bottom: 24px;

      @media ${SCREENS.tb_min} {
        max-width: unset;
        margin-bottom: 0;
      }

      p {
        ${FUNC.font(32, 700, COLORS.white)}
        line-height: 1.1;

        @media ${SCREENS.tb_min} {
          ${FUNC.font(44, 700, COLORS.white)}
          line-height: 1.1;
        }

        @media ${SCREENS.dsB_min} {
          ${FUNC.font(55, 700, COLORS.white)}
          line-height: 1.1;
        }
      }
    }

    &__text {
      max-width: 290px;
      width: 100%;

      &--text {
        text-align: center;
        margin-bottom: 30px;

        @media ${SCREENS.tb_min} {
          text-align: left
        }

        p {
          ${FUNC.font(14, 600, COLORS.white)}
          line-height: 1.4;

          @media ${SCREENS.tb_min} {
            ${FUNC.font(16, 600, COLORS.white)}
            line-height: 1.4;
          }
        }
      }
    }
  }
`;