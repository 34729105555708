// LIBs
import styled from "styled-components";

// INCLUDEs
import { COLORS, FUNC, SCREENS } from "../../core/StyleSheets/includes";

export const Container = styled.div`
  min-width: 130px;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;

  &:hover {
    .HilightItemContainer {
      &__image {
        img {
          transform: scale(1.05);
        }
      }
    }
  }

  .HilightItemContainer {
    height: 100%;
    background-color: ${COLORS.white};

    display: flex;
    flex-direction: column;

    &__title {
      padding: 16px 24px;
      text-align: center;
      background-color: ${COLORS.white};

      position: relative;
      z-index: 1;

      p {
        ${FUNC.font(16, 700)}
        line-height: 4vw;

        @media ${SCREENS.dsS_min} {
          ${FUNC.font(22, 700, COLORS.black, 22)};
        }
      }
    }

    &__image {
      flex: 1;

      img {
        width: 100%;
        height: 100%;
        max-height: 350px;
        transition: all ease 0.5s;

        object-fit: cover;
        object-position: center center;
      }
    }

    &__button {
      z-index: 1;

      button {
        min-height: 71px;
        border-radius: 0 0 10px 10px;
      }
    }
  }
`;
