// LIBs
import styled from "styled-components";

// INCLUDEs
import { COLORS, FUNC, SIZES } from "../../core/StyleSheet/includes";

export const Container = styled.div`
  @media ${SIZES.tb} {
    display: flex;
    gap: 40px;
    align-items: center;

    &.order-right {
      flex-direction: row-reverse;
    }
  }

  @media ${SIZES.dsS} {
    justify-content: space-between;
  }

  .BlockOperationDetails {
    margin-bottom: 30px;

    @media ${SIZES.tb} {
      width: calc(50% - 20px);
    }

    @media ${SIZES.dsS} {
      width: calc(40% - 20px);
    }

    &__idView {
      margin-bottom: 20px;

      p {
        ${FUNC.font('4vw', 200, '#000')}

        @media ${SIZES.mbB} {
          font-size: 18px;
          line-height: 28px;
        }
      }
    }

    &__title {
      margin-bottom: 10px;

      p {
        max-width: 61vw;
        ${FUNC.font('5.5vw', 200, '#000', '8vw')}

        @media ${SIZES.mbB} {
          max-width: 259px;
          font-size: 24px;
          line-height: 34px;
        }
      }
    }

    &__text {
      p {
        ${FUNC.font('4vw', 200, '#000', '6vw')}

        @media ${SIZES.mbB} {
          font-size: 18px;
          line-height: 28px;
        }
      }
    }
  }

  .BlockOperationImage {
    @media ${SIZES.tb} {
      width: calc(50% - 20px);
    }

    @media ${SIZES.dsS} {
      &.larger-image {
        width: calc(60% - 20px);
      }

      &.small-image {
        width: calc(40% - 20px);
      }
    }

    img {
      width: 100%;
    }
  }
`;