// COMPONENTs
import Card from "../../../components/Card";

// STYLEs
import * as C from "./styles";

export default props => {
  return (
    <C.Container id="contact-us">
      <picture>
        <source srcSet={props.data.image_lite} type="image/webp"/>
        <source srcSet={props.data.image} type="image/png" />
        <img 
          src={props.data.image} 
          className="ContactUsBg" 
          width={425}
          height={1024}
          alt={props.data.title}
        />
      </picture>

      <div className="ContactUsContainer">
        <div className="ContactUsContainer__texts">
          <div className="ContactUsTitle">
            <h3>{props.data.title}</h3>
          </div>

          <div className="ContactUsText">
            <p>{props.data.phrase}</p>
          </div>
        </div>

        <div className="ContactUsContainer__list">
          { (props.contactForms && props.contactForms.length > 0) &&
            props.contactForms.map((element, index) => (
              <Card 
                src={element.icon}
                srcLite={element.icon_lite}
                title={element.title}
                text={element.textarea}
                key={index}
              />
            ))
          }
        </div>
      </div>
    </C.Container>
  )
}