// LIBs
import styled from "styled-components";

// INCLUDEs
import { COLORS, FUNC, SCREENS } from "../../core/StyleSheets/includes";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(10px);
  opacity: 0;
  pointer-events: none;
  transition: all ease 0.8s;

  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;

  &.active-policy-cookie {
    opacity: 1;
    pointer-events: all;

    .PolicyCookieCotainer {
      margin-top: 0;
    }
  }

  .PolicyCookieCotainer {
    max-width: 768px;
    width: 100%;
    background-color: ${COLORS.white};
    border-radius: 10px;
    margin-top: 20px;
    overflow: hidden;
    transition: all ease 0.8s;

    &__header {
      padding: 20px;
      border-bottom: 2px solid ${COLORS.second};

      display: flex;
      justify-content: space-between;
      align-items: center;

      p {
        font-size: 24px;
        font-weight: 600;
      }

      button {
        font-size: 24px;

        display: flex;
        align-items: center;
      }
    }

    &__content {
      padding: 20px;
      max-height: 75vh;
      overflow-y: auto;

      &--container {
        display: flex;
        flex-direction: column;
        gap: 20px;

        ul {
          list-style: disc;
          padding-left: 20px;
          margin-left: 20px;
        }

        a {
          color: ${COLORS.second};

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
`;
