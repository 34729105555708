// COMPONENTs
import FAQCard from "../../../components/FAQCard";

// STYLEs
import * as C from "./styles";

export default props => {

  return (
    <C.Container>
      <div className="FAQDetailsBanner">
        <picture>
          <source srcSet={props.data.banner_lite} type="image/webp" />
          <source srcSet={props.data.banner} type="image/png" />
          <img
            src={props.data.banner}
            width={425}
            height={110}
            alt={props.data.title}
          />
        </picture>
      </div>

      <div className="FAQDetailsTitle">
        <h1>{props.data.title}</h1>
      </div>

      <div className="FAQDetailsList">
        {(props.faq.length > 0 && props.faq.length > 0) &&
          props.faq.map((element, index) => (
            <FAQCard
              title={element.question}
              body={element.response}
              show={props.activeFaq === index}
              onClick={() => {
                if (element.response) {
                  props.onActiveFaq(index)
                }
              }}
              key={index}
            />
          ))
        }
      </div>
    </C.Container>
  )
}