export default {
  // DEFAULT SCREENs
  mbS: "only screen and (min-width: 375px)",
  mb: "only screen and (min-width: 400px)",
  mbB: "only screen and (min-width: 450px)",
  tb: "only screen and (min-width: 768px)",
  dsS: "only screen and (min-width: 1024px)",
  ds: "only screen and (min-width: 1280px)",
  dsB: "only screen and (min-width: 1440px)",

  // DEFAULT SCREENs - VARIANT
  mbV: "only screen and (min-width: 561px)",
  tbV: "only screen and (min-width: 991px)",
  dsV: "only screen and (min-width: 1680px)",

};
