// LIBs
import styled from "styled-components";

// INCLUDEs
import { COLORS, FUNC, SCREENS } from "../../../core/StyleSheets/includes";

export const Container = styled.div`
  .HilightInfoContainer {
    max-width: 768px;
    width: 100%;
    margin: 0 auto;
    padding: 0 16px;

    @media ${SCREENS.dsS_min} {
      max-width: 1280px;

      display: flex;
      gap: 40px;
      align-items: center;
    }

    &__image {
      margin-bottom: 20px;

      @media ${SCREENS.dsS_min} {
        flex: 1;
      }

      img {
        width: 100%;
        height: auto;
        object-fit: scale-down;
        object-position: center;
      }
    }

    &__details {
      @media ${SCREENS.dsS_min} {
        flex: 1;
      }

      .HilightTitle {
        margin-bottom: 20px;

        h1 {
          font-size: 7vw;
          font-weight: 700;
          line-height: 1.1;

          @media ${SCREENS.mbB_min} {
            ${FUNC.font(30, 700)}
          }
        }
      }

      .HilightText {
        p {
          font-size: 14px;
          font-weight: 400;
          color: #333;
          line-height: 1.4;
        }
      }
    }
  }
`;