// LIBs
import styled from "styled-components";

// INCLUDEs
import { COLORS, FUNC, SCREENS } from "../../../core/StyleSheets/includes";

export const Container = styled.section`
  padding-top: 40px;

  @media ${SCREENS.dsS_min} {
    padding-top: 120px;
  }

  .TextContainer {
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;

    display: flex;
    flex-direction: column;
    gap: 20px;

    h1 {
      font-size: 24px;
      font-weight: 600;
    }

    h2 {
      font-size: 20px;
      font-weight: 600;
    }

    ul {
      list-style: disc;
      padding-left: 20px;
      margin-left: 20px;
    }

    a {
      color: ${COLORS.second};

      &:hover {
        text-decoration: underline;
      }
    }
  }
`;