// LIBs
import styled from "styled-components";

// INCLUDEs
import { COLORS, FUNC, SIZES } from "../../core/StyleSheet/includes";

export const Container = styled.div`
  padding: 60px 0;

  .OperationContainer {
    max-width: 450px;
    width: 100%;
    padding: 0 20px;
    margin: 0 auto;

    @media ${SIZES.tb} {
      max-width: 1024px;
    }

    &__title {
      margin-bottom: 30px;

      h3 {
        ${FUNC.font('7vw', 400)}

        @media ${SIZES.mbB} {
          font-size: 30px;
          line-height: 32px;
        }

        @media ${SIZES.dsS} {
          font-size: 48px;
          line-height: 50px;
        }
      }
    }

    &__list {
      display: flex;
      flex-direction: column;
      gap: 60px;
    }
  }
`;