export default {
  // DEFAULT COLORs
  first: "#19376E",
  second: "#00AFEF",

  // DEFAUT COLORs - DARK
  first_dark: "#07154C",
  second_dark: "#008DCD",

  // DEFAUT COLORs - LIGHT
  first_light: "#3B598F",
  second_light: "#22CFFF",
}
