export default {
  font: (fontSize, fontWeight, color = "#000", lineHeight = `calc(${fontSize} + 2px)`) => {
    return `
      font-size: ${fontSize};
      font-weight: ${fontWeight};
      color: ${color};
      line-height: ${lineHeight};
    `;
  },
};
