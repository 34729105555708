//LIBs
import React from "react";

//STYLEs
import { Container } from "./styles";

//STYLESHEETs
import { COLORS } from "../../core/StyleSheets/includes";

const AdvantageCard = (props) => {
  const Icon = require("react-icons/fi")[props.icon];

  return (
    <Container>
      <div className="Advantage__Icon">
        <Icon className="Page-Api-Advantages__Icon" color={COLORS.white} />
      </div>
      <div className="Advantage__Content">
        <p className="Advantage__Title">{props.title}</p>
        <p
          className="Advantage__Subtitle"
          dangerouslySetInnerHTML={{ __html: props.subtitle }}
        ></p>
      </div>
    </Container>
  );
};

export default AdvantageCard;
