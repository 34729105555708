// LIBs
import styled from "styled-components";

// INCLUDEs
import { COLORS, FUNC, SIZES } from "../../core/StyleSheet/includes";

export const Container = styled.section`
  min-height: 100vh;
  padding: 40px 0;
  padding-top: 100px;
  background: linear-gradient(115deg, ${COLORS.first_dark} 15%, ${COLORS.first} 80%, ${COLORS.first_light});

  display: flex;
  flex-direction: column;

  @media ${SIZES.tb} {
    min-height: auto;
  }

  .BannerContainer {
    max-width: 450px;
    width: 100%;
    flex: 1;
    padding: 0 20px;
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media ${SIZES.tb} {
      max-width: 1280px;

      flex-direction: row;
      gap: 40px;
      align-items: center;
    }

    &__texts {
      margin-bottom: 40px;

      @media ${SIZES.tb} {
        width: calc(50% - 20px);
      }

      @media ${SIZES.dsS} {
        width: calc(40% - 20px);
      } 

      .BannerTitle {
        margin-bottom: 10px;
  
        h1 {
          ${FUNC.font('9vw', 400, '#FFF')}

          @media ${SIZES.mbB} {
            font-size: 38px;
            line-height: 40px;
            text-align: center
          }

          @media ${SIZES.tb} {
            text-align: left
          } 

          @media ${SIZES.dsS} {
            font-size: 40px;
            line-height: 50px;
          }
        }
      }
  
      .BannerSubtitle {  
        h2 {
          ${FUNC.font('5vw', 200, '#FFF')}
          letter-spacing: 0.8px;

          @media ${SIZES.mbB} {
            font-size: 20px;
            line-height: 22px;
            text-align: center;
          }

          @media ${SIZES.tb} {
            text-align: left;
          } 

          @media ${SIZES.dsS} {
            font-size: 22px;
            line-height: 26px;
          }
        }
      }
    }

    &__banner {
      @media ${SIZES.tb} {
        width: calc(50% - 20px);
      }

      @media ${SIZES.dsS} {
        width: calc(60% - 20px);
      } 

      img {
        width: 100%;
      }
    }
  }
`;