// LIBs
import styled from "styled-components";

// STYLESHEET
import { COLORS, SCREENS } from "../../core/StyleSheets/includes";

export const Container = styled.div`
  padding: 8px 0;
  
  @media ${SCREENS.dsS_min} {
    padding: 13px 0;
  }

  .ProductItem {
    display: flex;
    gap: 8px;
    align-items: center;

    &__icon {
      width: 56px;
      height: 56px;
      border-radius: 50%;
      background-color: ${COLORS.first};

      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        font-size: 24px;
        color: ${COLORS.white};
      }
    }

    &__label {
      p {
        font-size: 4vw;
        line-height: 5vw;
        font-weight: 600;

        @media ${SCREENS.tb_min} {
          font-size: 2vw;
          line-height: 3vw;
        }

        @media ${SCREENS.dsS_min} {
          font-size: 24px;
          line-height: 34px;
        }
      }
    }
  }
`;
