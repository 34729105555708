export default {
  // SCREENS MIN
  dsB_min: "only screen and (min-width: 1440px)",
  ds_min: "only screen and (min-width: 1280px)",
  dsS_min: "only screen and (min-width: 1024px)",
  tb_min: "only screen and (min-width: 768px)",
  mbB_min: "only screen and (min-width: 450px)",
  mb_min: "only screen and (min-width: 375px)",

  // SCREENS MIN VARIANT
  dsV_min: "only screen and (min-width: 1980px)",
  tbV_min: "only screen and (min-width: 991px)",
  mbV_min: "only screen and (min-width: 360px)",
}