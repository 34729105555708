// LIBs
import styled from "styled-components";

// INCLUDEs
import { COLORS, FUNC, SCREENS } from "../../../core/StyleSheets/includes";

export const Container = styled.div`
  padding-top: 93px;
  background: radial-gradient(
    1529.97% 83.3% at 71.56% 50%,
    #a6b6ed 0%,
    rgba(208, 219, 255, 0.59) 99.57%
  );
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: inset 0 -40px 0 ${COLORS.white};
  margin-bottom: 20px;
  border-bottom: 1px solid ${COLORS.white};

  @media ${SCREENS.tb_min} {
    box-shadow: none;
    margin-bottom: 60px;
  }

  .Page-Api-Banner {
    &__Content {
      max-width: 1280px;
      width: 100%;
      padding: 24px 16px 0 16px;
      display: flex;
      flex-direction: column;

      @media ${SCREENS.tb_min} {
        flex-direction: row;
      }
    }

    &__Content-Container {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: center;

      @media ${SCREENS.tb_min} {
        width: 50%;
        justify-content: start;
        padding:40px 0;
      }
    }

    &__CTA-Container {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }

    &__Texts {
      display: flex;
      flex-direction: column;
      gap: 16px;
      max-width: 470px;
    }

    &__Title {
      ${FUNC.font(28, 600, COLORS.black)}
      line-height:120%;

      @media ${SCREENS.mb_min} {
        ${FUNC.font(32, 600, COLORS.black)}
      }
    }

    &__Subtitle {
      p {
        ${FUNC.font(14, 400, COLORS.black)}
        line-height:120%;

        @media ${SCREENS.mb_min} {
          ${FUNC.font(16, 400, COLORS.black)}
        }
      }
    }

    &__Button {
      border-radius: 5px;
      width: 100%;
      padding: 16px 64px;

      @media ${SCREENS.tb_min} {
        width: auto;
      }
    }

    &__Mockup-Container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;

      @media ${SCREENS.tb_min} {
        width: 50%;
      }
    }

    &__Mockup-Image {
      transform: translateY(5%);
      width: 100%;
      height: auto;
      min-height:80px;

      @media ${SCREENS.mb_min} {
        width: auto;
      }

      @media ${SCREENS.tb_min} {
        transform: translateY(20%);
      }
    }
  }
`;
