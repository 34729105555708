export default {
  // COLORS NAME DEFAULT
  black: "#000",
  white: "#FFF",

  // APPLICATION COLORS DEFAULT
  first: "#19376E",
  second: "#012677",
  // second: "#00AFEF",
  third: "#7FCCF5",
  fourth: "#152457",
  fifth: "#0D1C3D",
  sixth: "#BAC8F9",
  seventh:"#DBE3FF",

  // COLORS NAME DEFAULT DARK
  white_d: "#DDD",

  // APPLICATION COLORS DARK DEFAULT
  first_d: "#07154C",
  second_d: "#739BA9",
  third_d: "#5DAAD3",
  fourth_d: "#030235",
  fifth_d: "#0B0A1B",

  // COLORS NAME DEFAULT LIGHT
  black_l: "#222",
  gray_l: "#F3F3F3",

  // APPLICATION COLORS LIGHT DEFAULT
  first_l: "#3B598F",
  second_l: "#22CFFF",
  third_l: "#9FEEF7",
  fourth_l: "#374679",
  fifth_l: "#2F3E5F",

  // APPLICATION COLORS DEFAULT RGB(A)
  first_rgba: "rgba(13,28,61,.8)",
  second_rgba: "rgba(11,25,52,.6)",
  third_rgba: "rgba(221,221,221,.3)",
  fourth_rgba: "rgba(255,255,255,.5)",
  fifth_rgba: "rgba(255,255,255,.3)",
  sixth_rgba: "rgba(255,255,255,.8)",
}