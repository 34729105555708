// IMAGEs
import logo from "../../assets/logo.png";

// STYLEs
import * as C from "./styles";

export default props => {
  return (
    <C.Container>
      <div className="LoadingDetails">
        <div className="LoadingDetails__image">
          <img src={logo} alt="" />
        </div>

        <div className="LoadingDetails__spin">
          <div />
        </div>
      </div>
    </C.Container>
  );
}