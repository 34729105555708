// LIBs
import styled from "styled-components";

// INCLUDEs
import { COLORS, FUNC, SCREENS } from "../../../core/StyleSheets/includes";

export const Container = styled.section`
  width: 100%;
  padding: 80px 0;
  padding-bottom: 120px;

  @media ${SCREENS.tb_min} {
    padding: 160px;
  }

  .ServicesContainer {
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
    padding: 0 16px;

    &__title {
      text-align: center;
      margin-bottom: 45px;

      h1 {
        font-size: 7vw;
        font-weight: 700;
        line-height: 1.1;
        color: ${COLORS.black};
        text-transform: uppercase;

        @media ${SCREENS.tb_min} {
          font-size: 2.5vw;
        }
      }
    }

    &__list {
      display: flex;
      flex-direction: column;
      gap: 50px;

      @media ${SCREENS.tb_min} {
        flex-direction: row;
        gap: 20px;
        justify-content: center;
      }

      .Card {
        @media ${SCREENS.tb_min} {
          min-width: 230px;
          flex: 1;
        }
      }
    }
  }
`;