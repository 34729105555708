// LIBs
import styled from "styled-components";

// INCLUDEs
import { COLORS, FUNC, SCREENS } from "../../../core/StyleSheets/includes";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  .Page-Api-About {
    &__Content {
      max-width: 1280px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column-reverse;
      gap: 24px;
      padding: 120px 16px;

      @media ${SCREENS.dsS_min} {
        flex-direction: row;
      }
    }

    &__About {
      display: flex;
      flex-direction: column;
      gap: 24px;
      width: 100%;

      @media ${SCREENS.tb_min} {
        max-width: 80%;
      }

      @media ${SCREENS.dsS_min} {
        width: 45%;
      }
    }

    &__Title {
      ${FUNC.font(28, 600, COLORS.black)}

      @media ${SCREENS.mbB_min} {
        ${FUNC.font(32, 600, COLORS.black)}
      }
    }

    &__Text p{
      ${FUNC.font(14, 400, COLORS.black)}

      @media ${SCREENS.mbB_min} {
        ${FUNC.font(16, 400, COLORS.black)}
      }
    }

    &__Image {
      width: 100%;
      height: min-content;

      @media ${SCREENS.tb_min} {
        max-width: 80%;
      }

      @media ${SCREENS.dsS_min} {
        max-width: 40%;
      }
    }
  }
`;
