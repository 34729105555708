// LIBs
import styled from "styled-components";

// INCLUDEs
import { COLORS, FUNC, SIZES } from "../../core/StyleSheet/includes";

export const Container = styled.section`
  padding: 60px 0;
  background-color: #FAFAFA;

  .AboutAppContainer {
    max-width: 450px;
    width: 100%;
    padding: 0 20px;
    margin: 0 auto;

    @media ${SIZES.tb} {
      max-width: 1024px;

      display: flex;
      gap: 40px;
      justify-content: space-between;
    }

    &__title {
      margin-bottom: 30px;

      @media ${SIZES.tb} {
        max-width: 430px;
        width: 100%;
      }

      h2 {
        ${FUNC.font('8vw', 400, '#000', '10vw')}

        @media ${SIZES.mbB} {
          font-size: 32px;
          line-height: 42px;
        }

        @media ${SIZES.dsS} {
          font-size: 44px;
          line-height: 54px;
        }
      }
    }

    &__details {
      @media ${SIZES.tb} {
        max-width: 394px;
        width: 100%;
      }

      .AboutAppSubTitle {
        margin-bottom: 10px;

        h3 {
          max-width: 263px;
          width: 62vw;
          ${FUNC.font('4vw', 200)};

          @media ${SIZES.mbB} {
            font-size: 16px;
            line-height: 18px;
          }

          @media ${SIZES.dsS} {
            max-width: 100%;
            font-size: 24px;
            line-height: 28px;
          }
        }
      }

      .AboutAppText {
        margin-bottom: 20px;

        p {
          ${FUNC.font('3.2vw', 200, '#000', '5vw')}
          letter-spacing: 1px;

          @media ${SIZES.mbB} {
            font-size: 14px;
            line-height: 24px;
          }
        }
      }

      .AboutAppButton {
        .LinkExternal {
          width: fit-content;
        }
      }
    }
  }
`;
