//LIBs
import { FiArrowRight, FiArrowLeft } from "react-icons/fi";
import { Container } from "./styles";

const Arrow = (props) => {
    return (
      <Container
        style={props.style}
        onClick={props.onClick}
        className={`${props.type === "left" && "left"}`}
      >
        {props.type === "left" ? <FiArrowLeft /> : <FiArrowRight />}
      </Container>
    );
  };
  
export default Arrow;
