// COMPONENTs
import BlockOperation from "../../components/BlockOperation";

// STYLEs
import * as C from "./styles";

export default props => {
  return (
    <C.Container className="Operation">
      <div className="OperationContainer">
        { props.data.length > 0 &&
          <div className="OperationContainer__title">
            <h3>{props.data[3].children[0][0].value}</h3>
          </div>
        }

        { props.data.length > 0 &&
          <div className="OperationContainer__list">
            {
              props.data[3].children.map((part, index) => (
                <BlockOperation 
                  idView={`/${(index + 1) < 10 ? `0${index + 1}` : (index + 1)}`}
                  title={part[1].value} 
                  text={part[2].value} 
                  image={part[3].file} 
                  rightOrder={index % 2 === 0 ? false : true}
                  key={index}
                />
              ))
            }
          </div>
        }
      </div>
    </C.Container>
  )
}