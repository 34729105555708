// STYLEs
import * as C from "./styles";

export default (props) => {
  const Icon = require("react-icons/fi")[props.iconName];

  return (
    <C.Container>
      <div className="ProductItem">
        <div className="ProductItem__icon">
          <Icon />
        </div>

        <div className="ProductItem__label">
          <p>{props.label}</p>
        </div>
      </div>
    </C.Container>
  );
}
