// LIBs
import styled from "styled-components";

// INCLUDEs
import { SCREENS, COLORS, FUNC } from "../../core/StyleSheets/includes";

export const Container = styled.main`
  flex: 1;
  padding-top: 65px;
  padding-bottom: 80px;

  @media ${SCREENS.tb_min} {
    padding-top: 125px;
    padding-bottom: 120px;
  }
`;
