// LIBs
import { useState } from "react";
import { toast } from "react-toastify";
import ReactGA from "react-ga4";

// COMPONENTs
import Input from "../../components/Input";
import Button from "../../components/Button";

// HELPERs
import { sendDash } from "../../core/utils/dashApi";

// STYLEs
import * as C from "./styles";

export default props => {
  const [ name, setName ] = useState("");
  const [ email, setEmail ] = useState("");
  const [ phone, setPhone ] = useState("");
  const [ subject, setSubject ] = useState("");
  const [ message, setMessage ] = useState("");
  const [ validate, setValidate ] = useState(true);
  const [ sendSuccess, setSendSuccess ] = useState(false);

  async function sendMesage() {
    if (name && email && phone && subject && email) {
      const req = await sendDash({ name, email, phone, subject, message });

      if (req.status === 200 && req.data.error !== []) {
        toast.info("Mensagem Enviada!");
        setSendSuccess(true);

        ReactGA.event({
          action: "send-contact",
          category: "send",
          label: `${name} got in touch`,
        });

        setName("");
        setEmail("");
        setPhone("");
        setSubject("");
        setMessage("");
      }

      setValidate(true);
    } else {
      setValidate(false);
    }
  }

  return (
    <C.Container className="Contact" id="Contact">
      <div className="ContactContainer">
        { props.data.length > 0 &&
          <div className="ContactContainer__title">
            <h4>{props.data[5].children[0][0].value}</h4>
          </div>
        }

        <div className="ContactContainer__form">
          <Input 
            type="text" 
            label="Nome *" 
            value={name} 
            onChange={event => setName(event.target.value)} 
            danger={(!validate && !name)}
          />
          <Input 
            type="email" 
            label="Email *" 
            value={email} 
            onChange={event => setEmail(event.target.value)} 
            danger={(!validate && !email)}
          />
          <Input 
            type="mask" 
            label="Telefone *" 
            mask="(99) 9 9999-9999" 
            value={phone} 
            onChange={event => setPhone(event.target.value)} 
            danger={(!validate && !phone)}
          />
          <Input 
            type="text" 
            label="Assunto *" 
            value={subject} 
            onChange={event => setSubject(event.target.value)}
            danger={(!validate && !subject)}
          />
          <Input 
            type="textarea" 
            label="Mensagem *" 
            value={message} 
            onChange={event => setMessage(event.target.value)}
            danger={(!validate && !subject)}
          />
          <Button label="Enviar" onClick={sendMesage} />

          { sendSuccess &&
            <div className="ContactInfo">
              <p>* Fique atento a sua caixa de email, entraremos em contato com você!</p>
            </div>
          }
        </div>
      </div>
    </C.Container>
  )
}