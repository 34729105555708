// LIBs
import { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { jwtDecode } from "jwt-decode";

// CONFIG
import config from "../config/App.config";

// VIEWs
import Home from "../../views/Home";
import Hilights from "../../views/Hilights";
import FAQ from "../../views/FAQ";
import PrivacyPolicy from "../../views/PrivacyPolicy";
import Documentation from "../../views/Documentation";

// COMPONENTs
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import NewFooter from "../../components/NewFooter";
import Button from "../../components/Button";
import Loading from "../../components/Loading";

// TEMPLATEs
import PromotionalDash from "../../templates/lp-promotional-dash/PromotionalDash";

// SERVICEs
import { getMenu } from "../services/Api";

// ICONs
import { FaArrowUp, FaWhatsapp } from "react-icons/fa";

// STYLESHEETs
import "react-toastify/dist/ReactToastify.css";
import PageApi from "../../views/PageApi";

let historyScroll = 0;

const cacheMenuName = "@mdsystem:menu";

function Router() {
  const [ showMenu, setShowMenu ] = useState(false);
  const [ colorMenu, setColorMenu ] = useState(false);
  const [ buttonScroll, setButtonScroll ] = useState(false);
  const [ menu, setMenu ] = useState([]);
  const [ loading, setLoading ] = useState(true);
  const [ elementScroll, setElementScroll ] = useState("");

  useEffect(() => {
    start();
    
    window.onscroll = scrollPage;
  }, [])

  useEffect(() => {
    if (elementScroll !== "" && !loading) {
      setColorMenu(false);
      scrollTo();
    }

    if (!(window.location.pathname === "/" || window.location.pathname ==="/hml/mdsystemweb/")) {
      setColorMenu(true);
    }

    if (!loading) {
      redirect();
    }
  }, [loading, elementScroll])

  async function start() {
    document.title = "Mdsystem Web";
    let cacheMenu = window.localStorage.getItem(cacheMenuName);

    if (cacheMenu) {
      cacheMenu = JSON.parse(cacheMenu);
      setMenu(cacheMenu);
    }

    const menuReq = await getMenu("default");
    const menuReqDecoded = jwtDecode(menuReq.data.token);
    menuReqDecoded.data.splice(3, 0, menuReqDecoded.data[menuReqDecoded.data.length-1])
    menuReqDecoded.data.pop()

    setMenu(menuReqDecoded.data);
    window.localStorage.setItem(cacheMenuName, JSON.stringify(menuReqDecoded.data));
  }

  function redirect() {
    if (window.location.href.indexOf("#") > -1) {
      const element = document.getElementById(window.location.href.split("#")[1]);
      
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
        });
      }
    }
  }

  function scrollTo() {
    const element = document.querySelector(elementScroll.indexOf("#") > -1 ? elementScroll : `#${elementScroll}`);

    if (element && element.innerHTML !== "") {
      const top = element.offsetTop;
      window.scrollTo({
        top,
        behavior: "smooth"
      });
      setElementScroll("");
    }
  }

  function scrollPage(event) {
    const home = document.getElementById("home");

    // console.log(window.scrollY);
    // console.log(historyScroll);

    if (window.scrollY > window.screen.height - ((window.screen.height / 4) * 2)) {
      setButtonScroll(true);
    } else {
      setButtonScroll(false);
    }
    
    if (document.querySelector(".HomeView")) {
      const homeHeight = home.clientHeight;
      // console.log(window.scrollY);

      if (window.scrollY > (homeHeight - ((homeHeight / 4) * 3))) {
        setColorMenu(true)
      } else {
        setColorMenu(false);
      }
    }

    historyScroll = window.scrollY;
  }

  function shownLoad() {
    setLoading(true);
  }

  function disabledLoad() {
    setLoading(false);
  }
  
  return (
    <BrowserRouter basename={config.pathname}>
      <Header 
        data={menu} 
        show={showMenu} 
        colored={colorMenu}
        onToggleMenu={setShowMenu}
        onScrollTo={setElementScroll}
      />
      <Routes>
        <Route 
          path="/" 
          element={<Home onShownLoad={shownLoad} onHideLoad={disabledLoad} />} 
        />
        <Route 
          path="/hilights/:index" 
          element={<Hilights onShownLoad={shownLoad} onHideLoad={disabledLoad} />} 
        />
        <Route 
          path="/page-api" 
          element={<PageApi onShownLoad={shownLoad} onHideLoad={disabledLoad} />} 
        />
        <Route 
          path="/faq" 
          element={<FAQ onShownLoad={shownLoad} onHideLoad={disabledLoad} />} 
        />
        <Route 
          path="/privacy-policy" 
          element={<PrivacyPolicy onShownLoad={shownLoad} onHideLoad={disabledLoad} />} 
        />
        <Route 
          path="/page-api-documentation" 
          element={<Documentation onShownLoad={shownLoad} onHideLoad={disabledLoad} />} 
        />
        <Route 
          path="/promotional-dash" 
          element={<PromotionalDash onShownLoad={shownLoad} onHideLoad={disabledLoad} />} 
        />
        <Route 
          path="*" 
          element={<Home onShownLoad={shownLoad} onHideLoad={disabledLoad} />} 
        />
      </Routes>
      {/* <Footer /> */}
      <NewFooter />
      <Button 
        label={<FaArrowUp />} 
        className={buttonScroll ? "ButtonArrowUp active-scroll" : "ButtonArrowUp"} 
        onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
        button={{
          "aria-label": "Voltar ao topo da página"
        }}
      />
      <Button 
        type="link-external"
        label={<FaWhatsapp />} 
        to="https://wa.me/5547991982664?text=Olá,%20quero%20fazer%20orçamento%20de%20um%20site!"
        className={buttonScroll ? "ButtonWhatsapp active-scroll" : "ButtonWhatsapp"} 
        button={{
          "aria-label": "Voltar ao topo da página"
        }}
      />
      { loading &&
        <Loading />
      }
      <ToastContainer 
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </BrowserRouter>
  )
}

export default Router;