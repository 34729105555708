// LIBs
import styled from "styled-components";

// INCLUDEs
import { COLORS, FUNC, SCREENS } from "../../core/StyleSheets/includes";

export const Container = styled.footer`
  background-color: ${COLORS.first}; 

  .FooterHeader {
    padding: 64px 0;

    &__container {
      max-width: 1280px;
      width: 100%;
      margin: 0 auto;
      padding: 0 20px;

      display: flex;
      flex-direction: column;
      gap: 40px;
      align-items: center;

      @media ${SCREENS.tb_min} {
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
      }

      &--item {
        max-width: 233px;

        flex: 1;

        &:last-child {
          .FooterDetails {
            &__list {
              gap: 20px;

              a {
                flex: 1;

                img {
                  width: 100%;
                }
              }
            }
          }
        }

        .FooterDetails {
          text-align: center;
          margin-bottom: 16px;

          display: flex;
          flex-direction: column;
          gap: 8px;

          h4 {
            font-size: 16px;
            color: ${COLORS.white};
            font-weight: 700;
            text-transform: uppercase;
            margin-bottom: 8px;
          }

          p {
            font-size: 12px;
            line-height: 22px;
            color: ${COLORS.white};
          }

          &__list {
            display: flex;
            gap: 8px;
            justify-content: center;

            a {
              transition: all ease 0.8s;

              display: flex;
              align-items: center;

              &.animate-link:hover {
                transform: rotate(360deg) scale(0.8);
              }
            }
          }
        }
      }
    }
  }

  .FooterBottom {
    background-color: ${COLORS.fourth};
    padding: 8px 0;

    &__container {
      max-width: 1280px;
      width: 100%;
      margin: 0 auto;
      padding: 0 20px;

      display: flex;
      flex-direction: column;
      align-items: center;

      @media ${SCREENS.tb_min} {
        flex-direction: row;
        justify-content: space-between;
      }

      p {
        font-size: 12px;
        color: ${COLORS.white};
      }

      a {
        font-size: 12px;
        color: ${COLORS.white};
        font-weight: 500;
        text-decoration: underline;
      }
    }
  }
`;
