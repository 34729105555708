//LIBs
import React from "react";

//STYLEs
import { Container } from "./styles";

//COMPONENTs
import StepItem from "../../../components/StepItem";

//SERVICEs
import { convertDataPageApi } from "../../../core/services/dataPageapi";

const PageApiSteps = (props) => {
  return (
    <Container>
      <div className="Page-Api-Steps__Content">
        {props.data.map((step, index) => (
          <React.Fragment key={index}>
            {index > 0 && <div className="Page-Api-Steps__Line"></div>}
            <StepItem
              step={index + 1}
              title={step.title}
              text={step.textarea}
            />
          </React.Fragment>
        ))}
      </div>
    </Container>
  );
};

export default PageApiSteps;
