// LIBs
import styled from "styled-components";

// INCLUDEs
import { COLORS, FUNC, SCREENS } from "../../core/StyleSheets/includes";

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 30px;

  label {
    &:nth-of-type(1),
    &:nth-of-type(2),
    &:nth-of-type(3),
    &:nth-of-type(4) {
      @media ${SCREENS.mbB_min} {
        width: calc(50% - 10px);
      }
    }

    input {
      width: 100%;
    }
  }

  .LabelCheck {
    margin: 0 auto;

    a {
      margin-right: 0 !important;
    }
  }

  .Button {
    padding: 20px;
    text-transform: uppercase;

    @media ${SCREENS.tb_min} {
      max-width: 344px;
      width: 100%;
      margin: 0 auto;
    }
  }
`;