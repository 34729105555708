//LIBs
import React from "react";

//STYLEs
import { Container } from "./styles";

//SERVICEs
import { convertDataPageApi } from "../../../core/services/dataPageapi";

const PageApiAbout = (props) => {
  return (
    <Container>
      <div className="Page-Api-About__Content">
        <div className="Page-Api-About__About">
          <p className="Page-Api-About__Title">{props.data.title}</p>
          <p
            className="Page-Api-About__Text"
            dangerouslySetInnerHTML={{ __html: props.data.textarea }}
          ></p>
        </div>
        <img
          src={props.data.image}
          alt="Imagem de um Programador"
          className="Page-Api-About__Image"
        />
      </div>
    </Container>
  );
};

export default PageApiAbout;
