// LIBs
import styled from "styled-components";

// INCLUDEs
import { COLORS, FUNC, SCREENS } from "../../core/StyleSheets/includes";

export const Container = styled.div`
  max-width: 450px;
  width: calc(100% - 20px);
  padding: 20px 0;
  background-color: #FFF;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  transform: translateY(calc(100% + 20px));
  transition: all ease 0.8s;

  position: fixed;
  left: 10px;
  bottom: 10px;
  z-index: 2;

  @media ${SCREENS.tb_min} {
    width: calc(100% - 40px);

    left: 20px;
    bottom: 20px;
  }

  &.popup-active {
    transform: translateY(0);
  }

  .PopupCookieContainer {
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
    padding: 0 16px;

    &__title {
      margin-bottom: 20px;

      h4 {
        font-size: 24px;
        font-weight: 600;
      }
    }

    &__text {
      margin-bottom: 40px;

      p {
        font-size: 14px;

        span {
          font-size: 14px;
          color: ${COLORS.second};
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    &__buttons {
      display: flex;
      flex-direction: column;
      gap: 20px;
      justify-content: flex-end;

      @media ${SCREENS.mbB_min} {
        flex-direction: row;
      }

      div {
        &:first-child {
          button {
            background-color: transparent;
            border: 1px solid ${COLORS.second};
            color: ${COLORS.second};

            &:hover {
              background-color: ${COLORS.second};
              color: ${COLORS.white};
            }
          }
        }
      }
    }
  }
`;
