// STYLEs
import * as C from "./styles";

export default props => {
  return (
    <C.Container id="contact">
      <div className="ContactContainer">
        <div className="ContactContainer__title">
          <h3>{props.data.title}</h3>
        </div>

        <div className="ContactContainer__form">
          {props.children}
        </div>
      </div>
    </C.Container>
  )
}