// LIBs
import styled from "styled-components";

// INCLUDES
import { COLORS, FUNC, SCREENS } from "../../core/StyleSheets/includes";

export const Container = styled.header`
  width: 100%;
  padding: 24px 0;
  transition: all ease .5s;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;

  &.color-menu {
    background-color: ${COLORS.first};
  }

  .HeaderContainer {
    padding: 0 16px;

    display: flex;
    gap: 20px;
    justify-content: space-between;
    align-items: center;

    @media ${SCREENS.tb_min} {
      max-width: 1280px;
      width: 100%;
      margin: 0 auto;
    }

    &__logo {
      position: relative;
      z-index: 1;

      a {
        max-width: 200px;
        width: 30vw;
        min-width: 96px;

        display: block;

        img {
          max-width:180px;
          width: 100%;
          height: auto;
        }
      }
    }

    &__button {
      position: relative;
      z-index: 1;

      @media ${SCREENS.tb_min} {
        display: none;
      }

      &.active-button {
        .ButtonMob {
          &__bar {
            &:nth-of-type(1) {
              transform: rotate(-45deg);
              margin-top: 0;
            }

            &:nth-of-type(2) {
              opacity: 0;
            }
            
            &:nth-of-type(3) {
              transform: rotate(45deg);
              margin-top: 0;
            }
          }
        }
      }

      .ButtonMob {
        width: 32px;

        position: relative;

        &__bar {
          width: 100%;
          height: 3px;
          background-color: ${COLORS.white};
          transition: all ease 0.5s;

          display: flex;
          flex-direction: column;
          justify-content: center;

          position: absolute;

          &:nth-of-type(1) {
            margin-top: -10px;
          }

          &:nth-of-type(3) {
            margin-top: 10px;
          }
        }
      }
    }

    &__menu {
      width: 100%;
      height: 100%;
      background-color: ${COLORS.first_rgba};
      transform: translateX(-100%);
      transition: all ease .5s;

      position: fixed;
      top: 0;
      left: 0;

      @media ${SCREENS.tb_min} {
        width: 80%;
        background-color: transparent;
        transform: translateX(0);

        position: relative;
        top: unset;
        left: unset;
      }

      &.active-menu {
        transform: translateX(0);
      }

      .Nav {
        width: 100%;
        height: 100%;

        display: flex;
        justify-content: center;
        align-items: center;

        @media ${SCREENS.tb_min} {
          display: unset;
        }

        &__container {
          width: 80%;

          @media ${SCREENS.tb_min} {
            width: 100%;

            display: flex;
            gap: 10px;
            justify-content: flex-end;
          }

          li {
            position:relative;
            padding-bottom: 10px;
            border-bottom: 1px solid ${COLORS.white};
            margin-bottom: 10px;
            text-align: center;

            @media ${SCREENS.tb_min} {
              max-width: 165px;
              width: 100%;
              margin: 0;
              padding: 0;
              border: 0;
            }

            &:last-child {
              padding-bottom: 0;
              border: 0;
              margin: 0;
            }

            a {
              width: 100%;
              padding: 5px 0;
              text-align: center;
              ${FUNC.font(14, 600, COLORS.white)}
              display: flex;
              gap: 10px;
              justify-content: center;
              align-items: center;

              @media ${SCREENS.tb_min} {
                width: fit-content;
                white-space: nowrap;
                padding: 8px 13px;
                text-transform: uppercase;

                position: relative;

                &::before {
                  content: "";
                  width: 0;
                  height: 2px;
                  background-color: ${COLORS.white};
                  transition: all ease .5s;
                  
                  position: absolute;
                  top: calc(100% - 5px);
                  left: 13px;
                }
              }

              &:hover {
                &::before {
                  width: calc(100% - 24px);
                }
              }
            }
          }
        }
      }
    }
  }
`;