// LIBs
import { useState } from "react";
import Slider from "react-slick";

// COMPONENTs
import BannerItem from "../../../components/BannerItem";

// IMAGEs
import border from "../../../assets/border-banner.png";
import borderLite from "../../../assets/border-banner.webp";

// STYLEs
import * as C from "./styles";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

export default props => {
  const [ currentSlide, setCurrentSlide ] = useState(0);

  const settingsSlider = {
    arrows: false,
    dots: true,
    autoplay: true,
    autoplaySpeed: 4000,
    cssEase: "linear",
    afterChange: handleAfterChange,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          dots: false
        }
      },
    ]
  };

  function handleAfterChange(current) {
    setCurrentSlide(current);
  }

  return (
    <C.Container id="home">
      <div className="BannerContainer">
        <Slider {...settingsSlider}>
          { (props.data && props.data.length > 0) &&
            props.data.map((element, index) => (
              <BannerItem 
                src={element.background}
                srcLite={element.background_lite}
                title={element.title}
                text={element.textarea}
                ariaHidden={!(currentSlide === index)}
                key={index}
              />
            ))
          }
        </Slider>
      </div>
      <div className="BannerBorder">
        <picture>
          <source srcSet={borderLite} type="image/webp" />
          <source srcSet={border} type="image/png" />
          <img src={border} alt="" />
        </picture>
      </div>
    </C.Container>
  )
}