// LIBs
import { useEffect } from "react";

// CONTAINERs
import PrivacyPolicyText from "../../containers/PrivacyPolicy/Text";

// STYLEs
import * as C from "./styles";

function PrivacyPolicy(props) {
  useEffect(() => {
    start();
  }, [])

  async function start() {
    document.title = "Mdsystem Web - Política de Privacidade";
    window.scrollTo(0, 0);
    props.onHideLoad();
  }
  
  return (
    <C.Container className="PrivacyPolicyView">
      <PrivacyPolicyText />
    </C.Container>
  )
}

export default PrivacyPolicy;