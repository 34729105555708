// STYLEs
import * as C from "./styles";

export default props => {
  return (
    <C.Container className="Banner" id="Banner">
      <div className="BannerContainer">
        <div className="BannerContainer__texts">
          { props.data.length > 0 &&
            <div className="BannerTitle">
              <h1>{props.data[0].children[0][0].value}</h1>
            </div>
          }

          { props.data.length > 0 &&
            <div className="BannerSubtitle">
              <h2>{props.data[0].children[0][1].value}</h2>
            </div>
          }
        </div>

        <div className="BannerContainer__banner">
          { props.data.length > 0 &&
            <img src={props.data[0].children[0][2].file} alt="" />
          }
        </div>
      </div>
    </C.Container>
  )
}