//LIBs
import React from "react";
import ReactGA from "react-ga4";

//STYLEs
import { Container } from "./styles";

//COMPONENTs
import Button from "../../../components/Button";
import PlansSlider from "../../../components/PlansSlider";

const PageApiPlans = (props) => {
  return (
    <Container id="page-api-plans">
      <div className="Page-Api-Plans__Content">
        <p className="Page-Api-Plans__Section-Title">PLANOS</p>
        <PlansSlider data={props.data} />
        <div className="Page-Api-Plans__Button-Container">
          <Button
            className="Page-Api-Plans__Button"
            label="Selecionar um Plano"
            type="link-external"
            to="https://pageapi.mdsystemweb.com.br/register-customer"
            onClick={() => {
              ReactGA.event({
                action: `Foi para os planos Page Api`,
                category: "click",
              });
            }}
          />
        </div>
      </div>
    </Container>
  );
};

export default PageApiPlans;
