// LIBs
import styled from "styled-components";

// INCLUDEs
import { COLORS, FUNC, SIZES } from "../../core/StyleSheet/includes";

export const Container = styled.div`
  .Button,
  .LinkExternal {
    border: 1px solid #000;
    padding: 10px 20px;
    border-radius: 5px;
    ${FUNC.font('3.3vw', 200)}
    background-color: transparent;
    transition: all ease 0.5s;

    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background-color: ${COLORS.first};
      border-color: ${COLORS.first};
      color: #FFF;
    }

    @media ${SIZES.mbB} {
      font-size: 14px;
      line-height: 16px;
    }
  }
`;
