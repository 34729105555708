//LIBs
import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";

//STYLEs
import { Container } from "./styles";

//CONTAINERs
import PageApiBanner from "../../containers/PageApi/Banner";
import PageApiAdvantages from "../../containers/PageApi/Advantages";
import PageApiAbout from "../../containers/PageApi/About";
import PageApiSteps from "../../containers/PageApi/Steps";
import PageApiPlans from "../../containers/PageApi/Plans";

//SERVICEs
import { getBlocks, getPlans } from "../../core/services/Api";

// UTILs
import { convertDataPageApiInObject } from "../../core/utils/CDPAIO-1.0.0";
import { jwtDecode } from "jwt-decode";

const cacheHomeName = "@mdsystem:home";

const PageApi = (props) => {
  const [blocks, setBlocks] = useState({});

  useEffect(() => {
    start();
  }, []);

  async function start() {
    props.onShownLoad();

    window.scrollTo(0, 0);

    let cacheBlocks = window.localStorage.getItem(cacheHomeName);

    if (cacheBlocks) {
      cacheBlocks = JSON.parse(cacheBlocks);
      setBlocks(cacheBlocks);

      props.onHideLoad(false);
    }

    const req = await getBlocks("default");
    const plansReq = await getPlans();
    const reqDecoded = jwtDecode(req.data.token);

    let blocks = {};

    if (req.status === 200 && reqDecoded.data.length > 0) {
      let newBlock = { ...convertDataPageApiInObject(reqDecoded.data), pageapi_plans: plansReq.data.data.customer };

      setBlocks(newBlock);
      window.localStorage.setItem(cacheHomeName, JSON.stringify(blocks));
    }

    ReactGA.event({
      action: "Promocional Page Api",
      category: "load-page",
    });

    props.onHideLoad();
  }

  function scrollTo(event) {
    const element = document.querySelector("#page-api-plans");

    event.preventDefault();
    const top = element.offsetTop - 40;
    window.scrollTo({
      top,
      behavior: "smooth",
    });

    ReactGA.event({
      action: `Botão planos page api`,
      category: "click",
    });
  }

  return (
    <Container className="HilightsView">
      { blocks.pageapi_banner &&
        <PageApiBanner onClick={scrollTo} data={blocks.pageapi_banner} />
      }
      { blocks.pageapi_vantagens &&
        <PageApiAdvantages data={blocks.pageapi_vantagens} />
      }
      { blocks.pageapi_sobre &&
        <PageApiAbout data={blocks.pageapi_sobre} />
      }
      { blocks.pageapi_steps &&
        <PageApiSteps data={blocks.pageapi_steps} />
      }
      { blocks.pageapi_plans &&
        <PageApiPlans data={blocks.pageapi_plans} />
      }
    </Container>
  );
};

export default PageApi;
