// LIBs
import styled from "styled-components";

// INCLUDEs
import { COLORS, FUNC, SCREENS } from "../../../core/StyleSheets/includes";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  .Page-Api-Plans {
    &__Content {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 24px;
      max-width: 1280px;
      width: 100%;
      padding: 40px 0;

      @media ${SCREENS.tb_min} {
        padding: 72px 0px;
      }
    }

    &__Section-Title {
      ${FUNC.font(32, 700, COLORS.black)}
    }

    &__Button-Container {
      width: 100%;
      padding:0 16px;

      @media ${SCREENS.tb_min} {
        width: auto;
      }
    }

    &__Button {
      ${FUNC.font(16, 700, COLORS.white)}
      margin-top:24px;
      border-radius: 5px;
      padding: 24px;
      width: 100%;

      @media ${SCREENS.tb_min} {
        ${FUNC.font(20, 700, COLORS.white)}
        width: 370px;
      }
    }
  }
`;
