// LIBs
import { jwtDecode } from "jwt-decode";
import { useState, useEffect } from "react";
import ReactGA from "react-ga4";

// CONTAINERs
import FAQDetails from "../../containers/FAQ/Details";

// SERVICEs
import { getBlocks } from "../../core/services/Api";

// UTILs
import { convertDataPageApiInObject } from "../../core/utils/CDPAIO-1.0.0";

// STYLEs
import * as C from "./styles";

function FAQ(props) {
  const [ blocks, setBlocks ] = useState({});
  const [ faqActive, setFaqActive ] = useState(null);
  
  useEffect(() => {
    start();
  }, [])

  async function start() {
    props.onShownLoad();

    document.title = "Mdsystem Web - FAQ";
    const req = await getBlocks("default");
    const reqDecoded = jwtDecode(req.data.token);

    if (req.status === 200 && reqDecoded.data.length > 0) {
      setBlocks(convertDataPageApiInObject(reqDecoded.data));
    }

    window.scrollTo(0, 0);
    props.onHideLoad();
  }

  function activeFaq(index) {
    if (index !== faqActive) {
      setFaqActive(index);
      ReactGA.event({
        action: `FAQ ${blocks.faq[index].question}`,
        category: "click",
      });
    } else {
      setFaqActive(null);
    }
  }

  return (
    <C.Container className="FAQView">
      { (blocks.titulo_do_faq && blocks.faq) &&
        <FAQDetails 
          data={blocks.titulo_do_faq} 
          faq={blocks.faq}
          activeFaq={faqActive} 
          onActiveFaq={activeFaq}
        />
      }
    </C.Container>
  )
}

export default FAQ;